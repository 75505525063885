import { alpha } from '@mui/material/styles';

export const scoreColor = (color: string, score: number) => {

  if (score < 50) {
    return alpha(color, 0.2)
  } else if (score < 100) {
    return alpha(color, 0.4)
  } else if (score < 110) {
    return alpha(color, 0.6)
  } else if (score < 120) {
    return alpha(color, 0.8)
  }else if (score < 140) {
    return alpha(color, 1.0)
  } else if (score < 160) {
    return alpha(color, 1.0)
  } else {
    return alpha(color, 1.0)
  }
}

export const rateColor = (color: string, rate: number) => {
  // return alpha(color, rate);
  if (rate < 0.1) {
    return alpha(color, 0.2)
  } else if (rate < 0.2) {
    return alpha(color, 0.4)
  } else if (rate < 0.4) {
    return alpha(color, 0.6)
  } else if (rate < 0.6) {
    return alpha(color, 0.8)
  } else if (rate < 0.8) {
    return alpha(color, 1.0)
  } else {
    return alpha(color, 1.0)
  }
}
