import React from "react";

import {AppBar, Box, Grid, Stack, Toolbar, Typography} from "@mui/material";
import {User} from '@auth0/auth0-spa-js';

import {Logo} from "@/assets/images";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner, UserProfile } from "@/components/atoms";

interface PageLayoutProps {
  children?: React.ReactNode;
}

const isUserAuthorised = (user: User|undefined, domain: string|undefined, scope: string|undefined) => {
  return user && user['https://' + domain + '/permissions']?.includes(scope)
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const scope = process.env.REACT_APP_AUTH0_SCOPE;
  const isAuthorised = isUserAuthorised(user, domain, scope);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Spinner />
      </Grid>
    );
  }

  return (
    <Box>
      <AppBar position="static" elevation={0} sx={{height: '48px'}}>
        <Toolbar variant="dense" sx={{justifyContent: 'space-between'}}>
          <Stack direction='row' spacing={4} sx={{alignItems: 'center'}}>
            <Box sx={{flexGrow: 0, width: '148px', height: '32px', mr: 2}}>
              <Logo/>
            </Box>
            <Typography variant='h6' sx={{textTransform: 'uppercase'}}>
              CONNECT DEMO
            </Typography>
          </Stack>
          <UserProfile/>
        </Toolbar>
      </AppBar>
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    flexGrow: 1,*/}
      {/*    height: "calc(100vh - 48px)",*/}
      {/*    overflow: "auto",*/}
      {/*  }}*/}
      {/*>*/}
        <div>
        {isAuthenticated && isAuthorised ? children : <Typography>You do not have permissions to view this App.</Typography>}
        </div>
      {/*</Box>*/}
    </Box>
  );
};
