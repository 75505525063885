import React from 'react';
import Draggable from 'react-draggable';

import Paper, { PaperProps } from '@mui/material/Paper';
import { Typography, Box, Stack, Chip, IconButton, SvgIcon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from '@mui/icons-material/Close';

import { IFact } from "@/interfaces";
import { interestIcon } from '@/utils';
import { ScoreBar, RateBar } from "@/components/atoms";

type FactProps = {
  fact: IFact;
  onClose: () => void;
};

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#fact-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const Fact: React.FC<FactProps> = ({fact, onClose}) => {

  const groupScores = fact.scores.filter(x => x.tag_id === x.tag_group_id).slice(0, 6);
  const maxGroupScore = Math.max.apply(Math, groupScores.map(x => x.score));

  const maxScore = Math.max.apply(Math, fact.scores.map(x => x.score));

  return (
    <DraggablePaper
      elevation={2}
      sx={{
        position: "fixed",
        top: 64,
        right: 16,
        width: '500px',
        height: 'calc(100% - 64px - 36px)',
        overflowY: 'hidden',
        borderRadius: '6px',
        p: 2,
        pr: 0
      }}
    >
      <Stack id='fact-dialog' spacing={4} sx={{ height: '100%', mb: 2, pr: 1, overflowY: 'auto'}} >
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Chip label={fact.geo_code} size="small" sx={{ mr: 1 }}/>
            <Typography variant="h5">{fact.geo_name}</Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>Top Scoring Passion Groups</Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {groupScores.map((group, groupIdx) => (
              <Grid xs={6} key={`top-group-${groupIdx}`}>
                <Stack
                  direction="row"
                  spacing={2}
                  p={1}
                  alignItems="center"
                  width="100%"
                  maxWidth="100%"
                  sx={{
                    backgroundColor: '#37414F',
                    borderRadius: '6px'
                  }}
                >
                  <SvgIcon
                    component={interestIcon(group.tag_name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: "40px" }}
                  />
                  <Box width="calc(100% - 48px)">
                    <Typography variant="body2" noWrap={true}>{group.tag_name}</Typography>
                    <ScoreBar score={group.score} maxScore={maxGroupScore}/>
                    <RateBar rate={group.rate}/>
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>Top Scoring Passions</Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {fact.scores.filter(x => x.tag_id !== x.tag_group_id).slice(0, 12).map((tag, tagIdx) => (
              <Grid xs={6} key={`top-score-${tagIdx}`}>
                <Stack
                  direction="row"
                  spacing={2}
                  p={1}
                  alignItems="center"
                  width="100%"
                  maxWidth="100%"
                  sx={{
                    backgroundColor: '#37414F',
                    borderRadius: '6px'
                  }}
                >
                  <SvgIcon
                    component={interestIcon(tag.tag_name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: "40px" }}
                  />
                  <Box width="calc(100% - 48px)">
                    <Typography variant="body2" noWrap={true}>{tag.tag_name}</Typography>
                    <ScoreBar score={tag.score} maxScore={maxScore}/>
                    <RateBar rate={tag.rate}/>
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>Most Popular Passions</Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {fact.rates.filter(x => x.tag_id !== x.tag_group_id).slice(0, 12).map((tag, tagIdx) => (
              <Grid xs={6} key={`top-rate-${tagIdx}`}>
                <Stack
                  direction="row"
                  spacing={2}
                  p={1}
                  alignItems="center"
                  width="100%"
                  maxWidth="100%"
                  sx={{
                    backgroundColor: '#37414F',
                    borderRadius: '6px'
                  }}
                >
                  <SvgIcon
                    component={interestIcon(tag.tag_name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: "40px" }}
                  />
                  <Box width="calc(100% - 48px)">
                    <Typography variant="body2" noWrap={true}>{tag.tag_name}</Typography>
                    <ScoreBar score={tag.score} maxScore={maxScore}/>
                    <RateBar rate={tag.rate}/>
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </DraggablePaper>
  )

}
