import { fetchData } from "@/api/utils";
import { AxiosRequestConfig } from "axios";

export const getTags = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/demo/tags`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const getScores = async (accessToken: string, variables: number[]) => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/demo/scores?query=${variables.join(',')}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const getFact = async (accessToken: string, geo_id: number) => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/demo/fact?gid=${geo_id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};
