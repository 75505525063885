import { FillLayer } from "react-map-gl";

export const baseLayer: FillLayer = {
  id: 'base',
  type: 'fill',
  'source-layer': 'US_STATE',
  paint: {
    'fill-outline-color': [
      'case', ['==', ['feature-state', 'score'], null], '#ECF3F4',
      'rgba(0,0,0,0.1)'
    ],
    'fill-color': [
      'case', ['==', ['feature-state', 'score'], null], '#ECF3F4',
      [
      'interpolate',
      ['linear'],
      ['feature-state', 'score'],
        0, '#212b3a',
        80, '#354a5f',
        100, '#486d84',
        110, '#5a92aa',
        120, '#6db9cf',
        160, '#82e1f2',
    ]],
    'fill-opacity': [
      'case',
      ['==', ['feature-state', 'score'], null],
      0.1,
      [
        'case',
        ['<', ['feature-state', 'score'], 0],
        0,
        1
      ]
    ]
  }
};

export const hoverLayer: FillLayer = {
  id: 'highlight',
  type: 'fill',
  'source-layer': 'US_STATE',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#FFFFFF',
    'fill-opacity': 0.3
  }
};

export const selectLayer: FillLayer = {
  id: 'select',
  type: 'fill',
  'source-layer': 'US_STATE',
  paint: {
    'fill-outline-color': '#F5B49F',
    'fill-color': '#F5B49F',
    'fill-opacity': 1.0
  }
};
