import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import {CssBaseline} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

import App from "./App";
import "./styles/styles.scss";

import { AuthContainer, ThemeContainer } from "@/components/containers";

ReactDOM.render(
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <AuthContainer>
          <ThemeContainer>
            <div className='app-container'>
              <CssBaseline />
              <App />
            </div>
          </ThemeContainer>
        </AuthContainer>
      </BrowserRouter>
    </StyledEngineProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
