import React, { useState, useCallback, useMemo } from 'react';

import Map, { Source, Layer, Popup, MapboxGeoJSONFeature, MapRef } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box, Chip, Typography } from "@mui/material";

import { baseLayer, hoverLayer, selectLayer } from './layers';
import { Overlay } from '@/components/molecules';
import { HoverInfo } from "@/interfaces";

// const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/cl3c3adtf001214ndmp4jcmn8';
const MAP_STYLE = 'mapbox://styles/mpilarczykstarcount/clkgquj99007501qpdl0ebfr5';

type ScoreMapProps = {}

export const ScoreMap: React.FC<ScoreMapProps> = () => {

  const [selected, setSelected] = useState<MapboxGeoJSONFeature | undefined>(undefined);
  const [hovered, setHovered] = useState<number>(0);
  const [cursor, setCursor] = useState<string>('auto');
  const [hoveredInfo, setHoveredInfo] = useState<HoverInfo | undefined>(undefined);

  const flyTo = (map: MapRef, longitude: number, latitude: number) => {
    map && map.flyTo({center: [longitude + 15, latitude], zoom: 3})
  };

  const onClick = useCallback(event => {
    const feature = event.features && event.features[0];
    if (feature) {
      if (feature.layer.id === 'base') {
        setSelected(feature);
        flyTo(event.target, feature.properties['centlon'], feature.properties['centlat']);
      }
    } else {
      setSelected(undefined);
    }
  }, []);

  const onHover = useCallback(event => {
    const feature = event.features && event.features[0];
    if (feature) {
      setHovered(feature.id);
      setHoveredInfo({
        name: feature.properties['name'],
        code: feature.properties['stusab'],
        latitude: feature.properties['centlat'],
        longitude: feature.properties['centlon']
      });
    } else {
      setHovered(0);
      setHoveredInfo(undefined);
    }
  }, []);

  const clearSelected = () => {
    setSelected(undefined);
  };

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const hoverFilter = useMemo(() => ['==', ['id'], hovered], [hovered]);
  const selectFilter = useMemo(() => ['==', ['id'], selected ? selected.id : 0], [selected]);

  return (
    <Map
      initialViewState={{
        longitude: -98.57,
        latitude: 39.82,
        zoom: 3
      }}
      projection={{name: 'mercator'}}
      minZoom={3.5}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      attributionControl={false}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onHover}
      onClick={onClick}
      interactiveLayerIds={['base']}
    >
      <Source id='state-source' type='vector' url='mapbox://mpilarczykstarcount.US_STATE'>
        <Layer beforeId="waterway-label" {...baseLayer} />
        <Layer beforeId="waterway-label" {...hoverLayer} filter={hoverFilter}/>
        <Layer beforeId="waterway-label" {...selectLayer} filter={selectFilter}/>
      </Source>
      <Overlay selected={selected} clear={clearSelected}/>
      {hoveredInfo &&
        <Popup
          latitude={hoveredInfo.latitude}
          longitude={hoveredInfo.longitude}
          closeButton={false}
          closeOnClick={false}
          offset={20}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Chip label={hoveredInfo.code} size="small" sx={{ mr: 1 }}/>
            <Typography>{hoveredInfo.name}</Typography>
          </Box>
        </Popup>
      }
    </Map>
  )
};
