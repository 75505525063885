import React from 'react';

import { useTheme } from "@mui/material/styles";
import { Box, Typography, Tooltip } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


import {rateColor, toPercentage} from '@/utils';

type RateBarProps = {
  rate: number;
};

export const RateBar: React.FC<RateBarProps> = ({rate}) => {

  const theme = useTheme();

  const maxWidth = 72
  const width = maxWidth * rate

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      <Tooltip title="Audience Penetration: The % of people in this location that are likely to have this passion" placement="left">
        <PeopleAltOutlinedIcon fontSize="small" color="action" sx={{ mr: 1}}/>
      </Tooltip>
      <Box
        sx={{
          height: '8px',
          width: width,
          backgroundColor: rateColor(theme.palette.info.main, rate),
          borderRadius: '6px',
          mr: 1
        }}
      />
      <Typography variant="overline" sx={{ mt: "1px", lineHeight: 1.5 }}>{toPercentage(rate)}</Typography>
    </Box>
  );
}
