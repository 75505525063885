import React, { useState, useEffect } from 'react';

import { useMap, MapboxGeoJSONFeature } from "react-map-gl";
import { useAuth0 } from "@auth0/auth0-react";
import {times} from "lodash";

import { Box, Stack, Autocomplete, TextField, Chip, Alert } from "@mui/material";

import { useTagList } from "@/hooks/useTagsList";
import { getScores, getFact } from "@/api/demo";
import { Fact } from "@/components/molecules";
import { ITag, IScore, IFact } from "@/interfaces";


type OverlayProps = {
  selected?: MapboxGeoJSONFeature;
  clear: () => void;
}

export const Overlay: React.FC<OverlayProps> = ({selected, clear}) => {

  const { current: map } = useMap();
  const { getAccessTokenSilently } = useAuth0();
  const { tagList } = useTagList();
  const [variable, setVariable] = useState<ITag | null>(null);
  const [scores, setScores] = useState<IScore[]>([]);
  const [fact, setFact] = useState<IFact | null>(null);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const updateScores = async (v: ITag) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await getScores(
        accessToken,
        [v.tag_id]
      );
      setScores(response.data);
    };
    if (variable) {
      updateScores(variable).then(() => setLoading(false));
    } else {
      if (firstTime) {
        setFirstTime(false);
      } else {
        resetMap()
      }
    }
  }, [variable]);

  useEffect(() => {
    map && scores.forEach(value => {
      map.setFeatureState(
        {id: value.geo_id, source: 'state-source', sourceLayer: 'US_STATE'},
        {score: value.score}
      )
    });
    map && map.flyTo({
      center: [-98.57, 39.82],
      zoom: 3
    });
  }, [scores, map]);

  useEffect(() => {
    const updateFact = async (s: MapboxGeoJSONFeature) => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await getFact(
        accessToken,
        Number(s.id) | 0,
      );
      setFact(response.data[0]);
      setShowIntro(false);
    };
    selected && updateFact(selected).then(() => setLoading(false));
  }, [selected]);

  const handleVariableSelect = (event: React.SyntheticEvent, value: ITag | null) => {
    setVariable(value);
    setFact(null);
    clear();
  }

  const handleFactClose = () => {
    setFact(null);
    clear();
  }

  const handleIntroClose = () => {
    setShowIntro(false);
  }

  const resetMap = () => {
    map && [...times(54)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'state-source', sourceLayer: 'US_STATE'},
        {score: null}
      );
    });
  };

  return (
    <>
      <Box sx={{ mt: 2, ml: 8, width: '100%'}}>
        <Stack direction="row" spacing={3}>
          <Autocomplete
            size="small"
            getOptionLabel={(option) => option.tag_name}
            groupBy={(option) => option.tag_group_name}
            options={tagList}
            value={variable}
            onChange={handleVariableSelect}
            renderInput={(params) => (
              <TextField {...params} label="Select a Passion" variant="filled" sx={{minWidth: '300px'}}/>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.tag_name}
                  size="small"
                  color="primary"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
          {showIntro &&
            <Alert severity="info" variant="outlined" onClose={handleIntroClose} sx={{ zIndex: 100 }}>
              Welcome to the Connect Demo. Select a passion or click on a location to get started.
            </Alert>
          }
        </Stack>
      </Box>
      {fact && <Fact fact={fact} onClose={handleFactClose}/>
      }
    </>
  )


}
