import React from 'react';

import { useTheme } from "@mui/material/styles";
import { Box, Typography, Tooltip } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';


import { scoreColor } from '@/utils';

type ScoreBarProps = {
  score: number;
  maxScore?: number;
};

export const ScoreBar: React.FC<ScoreBarProps> = ({score, maxScore}) => {

  const theme = useTheme();
  const goodScore = score >= 100;

  const width = goodScore ?
    maxScore ?
      Math.min((score - 100) / (maxScore - 90), 1) * 100
      : Math.min(score / 200, 1) * 100
    : (score - 100) / 10;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Tooltip title="Index Score: How does the penetration for this passion compare to the overall average. A score of less than 100 suggests that people are less likely to have this passion" placement="left">
        <SpeedIcon fontSize="small" color="action" sx={{ mr: 1}}/>
      </Tooltip>
      <Box
        sx={{
          height: '8px',
          width: `${width + 10}%`,
          backgroundColor: goodScore ? scoreColor(theme.palette.info.main, score) : scoreColor(theme.palette.error.main, score),
          borderRadius: '6px',
          mr: 1
        }}
      />
      <Typography variant="overline" sx={{ mt: "1px", lineHeight: 1.5 }}>{score}</Typography>
    </Box>
  );
}
