import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { getTags } from "@/api/demo";
import { ITag } from "@/interfaces";


export const useTagList = () => {
	const { getAccessTokenSilently } = useAuth0();
	const [tagList, setTagList] = useState<ITag[]>([]);
	const [tagListLoading, setTagListLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		setTagListLoading(true);
		getAccessTokenSilently().then((accessToken) =>
			getTags(accessToken).then((response) => {
				setTagList(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setTagListLoading(false);
			}));
	}, []);

	return {tagList, tagListLoading, error};
}
